<template>
  <el-container class="login">
    <el-main>
      <div class="left">
        <img src="@/assets/imgs/logo.png" />
        <h1>{{platformName}}</h1>
        <p>Intelligent Tool Management System of Wuling Assembly Workshop</p>

        <div class="copy-right">
          <!-- <p><img src="@/assets/imgs/gongan.png" />xxx yyyewrewrwer3</p> -->
          <p><a href="https://beian.miit.gov.cn/">渝ICP备2021007239号-1</a></p>
        </div>
      </div>
      <div class="right">
        <el-form :model="formData" :rules="formRules" ref="formRef">
          <h1>Sign in</h1>
          <el-form-item label="账号" prop="account">
            <el-input v-model="formData.account" autocomplete="off" class="login-input"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="credential">
            <el-input type="password" v-model="formData.credential" autocomplete="off" class="login-input"></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="captcha">
            <el-input v-model="formData.captcha" class="login-input"></el-input>
            <img :src="captchaUrl" @click="UploadCaptchaUrl"/>
          </el-form-item>
          <el-button @click="handleLogin('formRef')" class="login-btn">登录</el-button>
        </el-form>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import { getUUID } from '@/utils/common.js'
import { login } from '@/api'
import { encode } from 'js-base64'

export default {
  name: 'Login',
  data() {
    return {
      platformName: window.PlatformNameShort,
      formData: {
        account: '',
        credential: '',
        captcha: ''
      },
      formRules: {
        account: { required: true, message: '请输入账号', trigger: 'blur' },
        credential: { required: true, message: '请输入密码', trigger: 'blur' },
        captcha: { required: true, message: '请输入验证码', trigger: 'blur' },
      },
      captchaUrl: '',
      uuid: ''
    }
  },
  created() {
    this.UploadCaptchaUrl()
  },
  methods: {
    // 获取验证码
    async UploadCaptchaUrl() {
      // 生成UUID
      let uuid = getUUID()
      
      this.uuid = uuid
      this.captchaUrl = window.commonConfigApi + `/public/captcha/${uuid}`
    },
    // 登录
    handleLogin(formName) {
      this.$refs[formName] && this.$refs[formName].validate && this.$refs[formName].validate(async (valid) => {
        if(valid) {
          let params = {
            ...this.formData,
            credential: encode(this.formData.credential),
            captchaKey: this.uuid
          }
          const res = await login.doLogin(params)
          if(res.data.code === 0) {
            localStorage.setItem('wlzcToken', res.headers.authorization)
            this.loadMenuAuth()
          } else {
            this.$message.error(res.data.msg)
          }
        }
      })
    },
    // 获取菜单权限
    async loadMenuAuth() {
      const {code, msg, data} = await login.getMenuAuth()
      if(code === 0) {
        localStorage.setItem('userMenuAuth', JSON.stringify(data))
        this.$router.push({name: 'home'})
      } else {
        this.$message.error(msg)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.el-container.login {
  display: flex;
  width: 100%;
  height: 100vh;
  .el-main {
    display: flex;
    padding: 0;
    background: url(~@/assets/imgs/login-bg.png) no-repeat left center / 100% 100%;
    .left {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;
      img {
        width: 7.6vw;
      }
      h1 {
        font-size: 1.8vw;
        color: #fff;
      }
      p {
        font-size: 14px;
        color: #fff;
      }
      .copy-right {
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 0;
        height: 58px;
        font-size: 12px;
        & > p {
          & + p {
            margin-left: 20px;
          }
          img {
            margin-right: 4px;
            position: relative;
            top: 3px;
          }
          a {
            color: #fff;
            text-decoration: none;
          }
        }
      }
    }
    .right {
      width: 36vw;
      @media screen and (min-width: 1500px) {
        width: 44vw;
      }
      height: 100%;
      display: flex;
      align-items: center;
      background: #fff;
      justify-content: center;
      .el-form {
        width: 68%;
        h1 {
          color: #5F5F8F;
          font-size: 1.8vw;
          margin-bottom: 45px;
        }
        .el-form-item {
          flex-direction: column;
          ::v-deep .el-form-item__label {
            text-align: left;
            color: #222222;
            font-size: 16px;
            font-weight: bold;
          }
          ::v-deep .el-form-item__content{
            display: flex;
            .el-input {
              line-height: 3.8vw;
              .el-input__inner {
                line-height: 3.8vw;
                height: 3.8vw;
              }
            }
            img {
              height: 3.8vw;
              margin-left: 25px;
              // max-width: ;
            }
          }
        }
      }
      .el-button {
        width: 100%;
        height: 3.8vw;
        background: #5F5F8F;
        font-size: 16px;
        color: #fff;
      }
    }
  }
}
</style>